import { render, staticRenderFns } from "./SongList.vue?vue&type=template&id=461f6f2b&scoped=true&"
import script from "./SongList.vue?vue&type=script&lang=ts&"
export * from "./SongList.vue?vue&type=script&lang=ts&"
import style0 from "./SongList.vue?vue&type=style&index=0&id=461f6f2b&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "461f6f2b",
  null
  
)

export default component.exports