
import Vue from 'vue'
import Component from 'vue-class-component'
import {SongModel} from "@/store/models";
import {Prop} from 'vue-property-decorator';

@Component({name: "SongList"})
export default class SongList extends Vue {

  $refs!: {
    scroll: HTMLFormElement
  }

  @Prop()
  scrollKey?: string
  @Prop({ required: true })
  songs!: SongModel[]
  scrollTop = 0
  lastScrollKey?: string
  
  activated() {
    if (this.lastScrollKey == this.scrollKey) {
      this.$refs.scroll.scrollTop = this.scrollTop
    }
  }

  deactivated() {
    this.scrollTop = this.$refs.scroll.scrollTop
    this.lastScrollKey = this.scrollKey
  }

  getTitle(song: SongModel): string {
    if (song.number) {
      return `${song.title} | ${song.number}`
    }
    return song.title
  }

  open(id: number) {
    this.$router.push("song/" + id.toString())
  }
}
