
import Vue from 'vue'
import Component from 'vue-class-component'
import {mdiCheckboxBlankOutline, mdiCheckboxMarked} from "@mdi/js"

@Component
export default class Settings extends Vue {
  checkBoxOff = mdiCheckboxBlankOutline
  checkBoxOn = mdiCheckboxMarked
  fontSize = 0

  get fontStyle() {
    return `font-size: ${this.fontSize}px`
  }
  created(){
    this.fontSize = this.$store.state.settings.fontSize
  }
  destroyed(){
    this.$store.commit("setFontSize", this.fontSize)
  }

  get playNotes() {
    return this.settings.playNotes
  }

  set playNotes(value: boolean) {
    this.$store.commit("setPlayNotes", value)
  }


  get settings() {
    return this.$store.state.settings
  }

  get darkTheme() {
    return this.settings.darkTheme
  }

  set darkTheme(value: boolean) {
    this.$store.commit("setDarkTheme", value)
    this.$vuetify.theme.dark = this.$store.state.settings.darkTheme
  }

}
