
import Vue from 'vue'
import Component from 'vue-class-component'
import {mdiMagnify, mdiMenu} from "@mdi/js"
import {Watch} from 'vue-property-decorator'
import {SongModel} from "@/store/models";

@Component
export default class AppBar extends Vue {
  showBar = false
  searchIcon = mdiMagnify
  menuIcon = mdiMenu
  title = "Все"
  isSearch = false
  
  mounted(){
    this.updateState()
  }

  get dark() {
    return this.$vuetify.theme.dark
  }

  toggleBar() {
    this.$store.commit("setShowBar", !this.$store.state.showBar)
  }

  get searchText() {
    return this.$store.state.searchText
  }

  set searchText(value: string) {
    if (this.$route.name != "Home") {
      this.$router.push({name: "Home"})
    }
    this.$store.commit("setSearchText", value)
  }
  
  @Watch("$route", {deep: true})
  updateState() {
    const routeName = this.$route.name
    if (routeName == "Home") {
      const tag = (this.$route.query.tag as string)
      if (tag) {
        this.searchText = ""
        this.title = tag
      } else if (this.searchText) {
        this.isSearch = true
      } else {
        this.title = "Все"
      }
      return
    }
    if (routeName == "SingleSong") {
      const songId = Number.parseInt(this.$route.params.id)
      const song = this.$store.state.songs.find((x: SongModel) => x.id == songId)
      this.title = (song.number ? song.number + '. ' : '') + song.title
      this.isSearch = false
      return
    } 
    this.title = this.$route.meta?.title ?? "Unknown"
    this.isSearch = false
  }
}
