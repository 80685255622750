
import Vue from 'vue'
import Component from 'vue-class-component'
import {Prop} from 'vue-property-decorator';
import Piano from "@/services/piano";
import {mdiMusicNote, mdiStar, mdiStarOutline} from "@mdi/js"

@Component
export default class SingleSong extends Vue {
  @Prop()
  id!: number
  noteIcon = mdiMusicNote
  favouriteIcon = mdiStar
  unFavouriteIcon = mdiStarOutline

  get isFavourite() {
    return this.$store.state.favourites.includes(this.id)
  }

  toggleFavourite() {
    this.$store.commit("toggleFavourite", this.id)
  }

  get fontStyle() {
    return `font-size: ${this.$store.state.settings.fontSize}px`
  }

  get song() {
    return this.$store.state.selectedSong
  }

  get showNote() {
    return this.$store.state.settings.playNotes && Piano.canPlay(this.song.note)
  }

  playNote() {
    Piano.play(this.song.note)
  }

  created(): void {
    this.$store.commit("selectSong", this.id)
  }

  goToTag(tag: string) {
    this.$router.push({name: "Home", query: {tag: tag}})
  }

}
