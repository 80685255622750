
import Vue from 'vue'
import Component from 'vue-class-component'

@Component
export default class TagList extends Vue {

  setTag(tag: string) {
    this.$router.push({name: "Home", query: {tag: tag}})
  }

  get tags() {
    return this.$store.getters["tags"]
  }
}
